import Chef from './images/Logo.jpg'
import Cart from './images/qr.svg'
const Header = () => {
  return (
    <div className="header-body border shadow-lg flex border-yellow-500 relative  ">
      <img className="w-24 pl-4 sm:w-16" src={Chef} alt="" />
      <ul className="List flex items-center text-2xl font-bold gap-5 p-4 mr-70 flex-wrap sm:text-xs">
        <li></li>
        <li className='hover:bg-yellow-500 rounded-lg p-1 px-1 hover:text-white'>
          <a href="#home">Home</a>
        </li>
        <li className='hover:bg-yellow-500 rounded-lg p-1 px-1 hover:text-white'>
          <a href="#about">About</a>
        </li>
        <li className='hover:bg-yellow-500 rounded-lg p-1 px-1 hover:text-white'>
          <a href="#menu">Services</a>
        </li>
       
      </ul>
      <img width={200} height={200} className="absolute w-24 pl-4 sm:w-16 right-6 sm:hidden" src={Cart} alt="" />
    </div>
  );
}
export default Header