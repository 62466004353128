const menu = [
  {
    id: 1,
    title: "Hair extensions",
    category: "Nuga’s Hairline",
    img: "./images/HairExtention.png",
    desc: `This is a versatile hair with full cuticles, it can be used to create that fabulous chic look 
    and it is one of the most amazing piece of hair you will ever own, this hair is completely sophisticated 
    and it's design keeps the hair shed free and tangle free. It is extremely soft, silky and lustrous, 
    and it will give you a full hair fix. It retains waves after several wash and it can be used severally. .`,
  },
  {
    id: 2,
    title: "Wigs",
    category: "Nuga’s Hairline",
    img: "./images/wig.png",
    desc: `Our curly human hair wig is a trendy and stylish hairpiece that offers a chic and 
    modern look which creates a natural-looking hairline and allows for versatile parting options. 
    The pixie cut is characterized by short, textured layers that frame the face and enhance facial features. 
    With its effortless style and easy maintenance, our human hair curly wig is perfect for those looking to change 
    up their look or try a bold and edgy hairstyle without committing to a permanent cut.`,
  },
  {
    id: 3,
    title: "Hair products and Accesories",
    category: "Nuga’s Hairline",
    img: "./images/hairProduct.png",
    desc: `Nuga hairline is a bussiness that specialises in the production and distribution of natural hair care products for the treatment of all hair problems. 
    w e have different hair products and accessories .`,
  },
  {
    id: 4,
    title: "Naija Original Palmoil",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/Palmoil.jpg",
    desc: `Palm oil also known as red oil is made from Palm Kanel ,it has less calories and it is used to make all kinds of 
    meal very easy to use taste nice and very nutritious, for the best taste of africa delicasies, we will get it you the natural and original palm oil`,
  },
  {
    id: 5,
    title: "Stock fish",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/stockfish.png",
    desc: `The best feeling is to add stock fish in your ogbonno soup, we will get you the best stock fish neat and fresh`,
  },
  {
    id: 6,
    title: "Ponmo Ijebu",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/ponmo.png",
    desc: `Dried cow skin also known as pomo is a popular African delicacy added to soups and stews. 
    Can be used for nkwobi and other sauces . Peppered pomo sauce can be taken as snacks. condiment 
    in every kitchen, Mix pomo with Rice, Beans, soup and many more. Very clean and neatly processed

    Simply soak in water for hours before use`,
  },
  {
    id: 7,
    title: "Panla Kika",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/panla.png",
    desc: `With its high-quality protein, omega-3 fatty acids, vitamins, and minerals, 
    panla fish can support heart health, brain function, skin health, and overall well-being.
     Just remember to enjoy it in moderation to minimize potential mercury exposure. 
     For thesewe got you covered, just tell us how you want it, and we will get it for you. no hassle`,
  },
  {
    id: 8,
    title: "Goat Meat / Cow meat / Assorted",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/goat.png",
    desc: `Our standard assorted meat consists of – goat meat, liver, kidney, shaki (tripe) and ponmo: all your 
    favorite cow meat packed in portion sizes. We deliver ...`,
  },
  {
    id: 9,
    title: "Kilishi",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/kilishi.png",
    desc: `All Natural Spicy Beef Jerky (Kilishi) ... Kilishi is a version of jerky that originated from Hausa, Northern part of Nigeria.`,
  },
  {
    id: 10,
    title: "Kulikuli",
    category: "Nuga’s Afrifoods/Delicacies",
    img: "./images/kulikuli.jpg",
    desc: `Kuli kuli is a popular nigerian snack made from groundnut. 
    It is especially rich in protein. It also contains calcium and magnesium 
    which protects against heart diseases. Highly rich in fibre. It contains an 
    antioxidant called resveratrol which fights cancer.  (1 Pack Filled With 50 Pieces)

    * Spiced with Pepper and Ginger
    * Tasty and Crunchy
    * Rich in Protein
    * Spicy, Delicious and Nutritious
    * Can be eaten in the Office or at home
    * Can be served in a party as a snack
    * Neatly prepared.`
  },{
    id: 11,
    title: "Nuga’s Autos",
    category: "Nuga’s Autos",
    img: "./images/nugaautos.jpg",
    desc: `Sales of clean used cars.`,
  },
  {
    id: 12,
    title: "Software Development ",
    category: "Nuga’s IT Consultancy",
    img: "./images/it.png",
    desc: `Contact us for all your website design or web programming, App development, process automation, software development etc`,
  }
];
export default menu;
