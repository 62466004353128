import { useEffect, useState } from "react";
import Header from "./Header";
import Neely from "./images/Banner.jpg";
import item from './Data';
import Services from "./Services";
import Categories from "./Categories";
import Footer from "./Footer";
import Video from "./images/Wigvideo.mp4";

const allCategories = ["all", ...new Set(item.map((item) => item.category))];

function App() {
  
  const [value, setValue] = useState([]);
  const [categories, setCategories] = useState(allCategories);
  const [menuItems, setMenuItems] = useState(item);

  const filterItems = (category) => {
    if (category === "all") {
      setMenuItems(item);
      return;
    }
    const newItems = item.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  const fetchData = async () => {
    const response = await fetch(item);
    const newValue = await response.json();
    setValue(newValue);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="relative ">
        <img className="w-full h-[2/4]" src={Neely} alt="" />
        <h2 className="absolute text-2xl text-white bottom-0 top-10 right-8 font-mono text-2xl font-bold  right-2">
          YOU THINK IT...WE MAKE IT HAPPEN{" "}
        </h2>
        <button className="p-4 bg-yellow-500 rounded-lg text-white text-2xl absolute bottom-6 align-center hover:bg-white hover:text-yellow-500 hover:p-3 right-6">
        <a target="_blank" href="https://wa.link/em80zb" className="p-4 bg-yellow-500 rounded-lg text-white text-2xl absolute bottom-6 align-center hover:bg-white hover:text-yellow-500 hover:p-3 right-6">
          Place Order
        </a>
        </button>
      </div>
      <div className="video-container" id="about">
  <h1 className="text-2xl text-center pt-4 font-bold">NUGA-DS</h1>
  <div className="w-[4rem] h-[0.25rem] ml-auto mr-auto bg-yellow-500 border mb-4"></div>
  <div className="p-4 text-center w-[50rem] mr-auto ml-auto text-xl sm:w-[20rem] sm:mr-auto sm:ml-auto sm:text-xs lg:text-md">
    <video width={250} height={100} loop muted>
      <source src={Video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
</div>


      <div className="About " id="about">
        <h1 className="text-2xl text-center pt-4 font-bold">About Us</h1>
        <div className="w-[4rem] h-[0.25rem] ml-auto mr-auto bg-yellow-500 border mb-4"></div>
        <h1 className="p-4 text-center w-[50rem] mr-auto ml-auto text-xl sm:w-[20rem] sm:mr-auto sm:ml-auto sm:text-xs lg:text-md">
          Nuga D’s Limited is a registered company dealing in Food products, Hair products
          and Used cars. Our Products and services are listed above but not limited to the list,
          as our brand lines are expanding and others coming up soon. Talk to us about that
          your need and see us make it happen. We are a company on a mandate and
          promise to make everywhere you are Home away from home and ensure as you
          think it, we make it happen without missing anything you like enjoying because your
          fulfilment and joy is our Pride.
        </h1>
      </div>
      <div className="Hours">
        <h1 className="text-2xl text-center pt-4 font-bold">Hours</h1>
        <div className="w-[4rem] h-[0.25rem] ml-auto mr-auto bg-yellow-500 border mb-4"></div>
        <div className="p-4 text-center w-[60rem] mr-auto ml-auto text-xl sm:w-[15rem] sm:mr-auto sm:ml-auto sm:text-xs flex gap-5 justify-center sm:flex-wrap md:flex-wrap font-mono">
          <div className="">
            <h3 className="font-thick">Mon-Thur</h3>
            <h4>7AM - 9PM</h4>
          </div>
          <div>
            <h3 className="font-thick">Friday</h3>
            <h4>7AM - 11PM</h4>
          </div>
          <div>
            <h3 className="font-thick">Saturday</h3>
            <h4>6AM - 10PM</h4>
          </div>
          <div>
            <h3 className="font-thick">Sunday</h3>
            <h4>Closed</h4>
          </div>
        </div>
      </div>
      <div className="Services" id="menu">
        <h1 className="text-2xl text-center pt-4 font-bold">services</h1>
        <div className="w-[4rem] h-[0.25rem] ml-auto mr-auto bg-yellow-500 border mb-4"></div>
        <Categories categories={categories} filterItems={filterItems} />
        <Services items={menuItems} />
      </div>
      <Footer className="mx-auto" />
    </div>
  );
}

export default App;
