import React from 'react';

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center', width: '100%', margin: 'auto' }}>
      <p/>
      <p/>
      <p/>Copyright &copy; 2024 Nuga-ds      <a target="_blank" href="https://www.linkedin.com/in/faymos/">Powered by Faymos by Oluwatosin</a>
      <p/>
      <p/>
      <p/>
      <p/>
    </footer>
  );
};

export default Footer;
